//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GrecaptchaTerms from '../grecaptchaTerms.vue';

export default {
  components: {
    GrecaptchaTerms,
  },
  props: {
    clickableLogo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMx() {
      return this.$nuxt.$config.COUNTRY === 'mx';
    },
    isBr() {
      return this.$nuxt.$config.COUNTRY === 'br';
    },

    directions() {
      const _value = this.$store.state.courses.directions.filter(
        (direction) => direction.coursesCount,
      );
      this.debug({ name: 'comp directions' }, '%O', _value);
      return _value;
    },
    documents() {
      const _value = this.$app.config.current.footerDocuments.map((val) => {
        let doc = this.$app.config.current.documents[val];
        if (typeof doc === 'string') {
          doc = {
            link: doc,
          };
        }

        doc.documentKey = val;
        return doc;
      });
      this.debug({ name: 'comp documents' }, '%O', _value);
      return _value;
    },

    grecaptchaEnabled() {
      return this.$app?.config?.current?.grecaptchaEnabled;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
